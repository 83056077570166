@import url('./static/styles/layout.css');
@import url('./components/shared/ui/modals/modal.css');
@import url('./static/styles/leftnav.css');
@import url('./static/styles/material-override.css');
@import url('./static/styles/grid.css');
@import url('./components/shared/ui/modals/modal.css');

:root {
    --main-grey: #3d3d3d;
    --main-green: #b9e258;
    --main-red: #f54545;
    --main-background: #ececec;
    --main-padding-x: 0vw;
    --main-padding-y: 2vh;
}

div#root {
    overflow-x: hidden;
}

main {
    min-width: 100vw;
    width: 100%;
    min-height: 100vh;
    height: 100%;
}

main > div:last-child {
    width: 100%;
    padding: var(--main-padding-y) var(--main-padding-x) 0 var(--main-padding-x);
    background-color: var(--main-background);
}

h1,h2,h3,img,a,p,svg {
    transition: all ease 0.4s;
    margin: 0;
}

h1,h2,h3 {
    color: grey;
}

.pointer {
    cursor: pointer;
}

*:focus-visible {
    outline: none !important;
}

/* SCROLLBAR */

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.37);
    border-radius: 10px;
}

.quickbook-status {
    text-transform: uppercase;
    font-size: 12px;
    position: relative;
    margin-left: 10px;
    color: white;
    transition: ease all 0.4s;
}

.quickbook-status.inactive {
    cursor: pointer;
    text-decoration: none;
}

.quickbook-status.inactive:hover {
    color: rgb(209, 209, 209);
}

.quickbook-status:before {
    content: '●';
    position: absolute;
    font-size: 22px;
    top: -8px;
    left: -17px;
}

.quickbook-status.active:before {
    color: var(--main-green);
}

.quickbook-status.inactive:before {
    color: var(--main-red);
}