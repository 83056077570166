.left-nav {
    width: 13em;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    gap: 5px;
    padding: 2em 0;
    border-right: 1px solid #19191924;
    background-color: var(--main-grey);
}

.left-nav button.home-btn {
    margin-bottom: 10vh;
}

.left-nav button img {
    max-width: 100px;
}

.left-nav div.menu-link {
    margin-right: 5%;
    border-radius: 0 20px 20px 0;
    /* transition: ease all 0.4s; */
    cursor: pointer;
    position: relative;
    width: 100%;
}

.left-nav div.menu-link:before {
    content: "";
    position:absolute;
    left:5px;
    top: 50%;
    transform: translateY(-50%);
    width: 7px;
    border-radius: 0 20px 20px 0;
    border-left: 1px solid rgb(24, 24, 24);
    height: 40px;
    background-color: white;
    opacity: 0;
    /* transition: opacity ease 0.2s; */
}

.left-nav div.menu-link.selected:before {
    opacity: 1;
}


.left-nav div.menu-link > div {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 30px;

}

.left-nav  div.menu-link svg,
.left-nav  div.menu-link p {
    color: rgba(255, 255, 255, 0.37);
}

.left-nav  div.menu-link p {
    padding-top: 1px;
    font-size: 18px;
    cursor: pointer;
    margin: 0;
    
}

.left-nav  div.menu-link:not(.selected):hover p,
.left-nav  div.menu-link:not(.selected):hover svg {
    color: rgba(255, 255, 255, 0.596);
}

.left-nav  div.menu-link.selected p,
.left-nav  div.menu-link.selected svg {
    color: white;
}