.MuiDataGrid-columnHeaderTitle {
    font-size: 12px;
    color: grey;
}

.MuiDataGrid-root {
    background-color: white;
}

.MuiDataGrid-columnHeader,
.MuiDataGrid-cell:focus {
    outline: none !important;
}

.MuiDataGrid-cell:not(.MuiDataGrid-cell--editable):focus-within {
    outline: none !important;
}

.MuiDataGrid-cell fieldset {
    outline: none !important;
    border: none !important;
}

.MuiDataGrid-cell:not(.MuiDataGrid-cell--editable)  .MuiInput-underline  input.MuiInput-input::before {
    border: none !important;
}

.MuiDataGrid-columnHeader:first-child,
.MuiDataGrid-cell:first-child {
    padding-left: 1.5rem !important;
}


.MuiDataGrid-cell--editable {
    cursor: pointer !important;
}

.MuiDataGrid-cell, .MuiDataGrid-cell input {
    font-size: 1rem !important;
}

span.favorite {
    color: #FFC107;
    cursor: pointer;
}

span.favorite > svg {
    font-size: 2.1em;
    transition: ease all 0.2s;
}

span.favorite:hover > svg {
    transform: scale(1.2);
}

span.role-status,
span.status {
    text-transform: uppercase;
    font-size: 9px;
    position: relative;
    margin-left: 10px;
}

span.role-status:before,
span.status:before {
    content: '●';
    position: absolute;
    font-size: 22px;
    top: -12px;
    left: -17px;
}

span.role-status.user:before,
span.status.inactive:before {
    color: var(--main-red);
}

span.role-status.admin:before,
span.status.active:before {
    color: var(--main-green);
}

span.render-open > svg.render-open-icon {
    font-size: 16px;
    color: #707070;
    opacity: 0;
    transition: ease all 0.4s;
    margin-left: 2px;
}

span.render-open:hover {
    color: black;
}

span.render-open:hover > svg.render-open-icon {
    opacity: 1;
}
