.MuiSelect-select {
    text-align: left !important;
}

/* SEARCH INPUT */
.search-input {
    background-color: transparent !important;
    
}
.search-input input {
    border-radius: 20px !important;
    background-color: rgba(255, 255, 255, 0.801);
    border: 1px solid rgba(199, 199, 199, 0.329);
    position: relative;
}

.clear-input {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    font-size: 30px;
    font-family: math;
    color: rgb(182, 182, 182);
}


.MuiTabPanel-root {
    padding: 0 !important;
}

.flex.column span.MuiButtonBase-root.MuiCheckbox-root {
    padding: 0 !important;
}